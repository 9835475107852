@import '../../../styles/variables';

header.header {
    box-shadow: 0 0 4px rgba(50, 50, 50, 0.25);
    width: 100%;
}

.language-selector {
    width: 100%;
    min-width: 200px;
    max-width: 300px;
}
