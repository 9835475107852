$minHeight: 20rem;

//Modal wrap
.body {
    min-height: $minHeight;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modalWrap {
    display: inline-flex;
    position: static;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 30px;

    .modal {
        width: 100%;
        max-width: 600px;
        background-color: #f8f8f8;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 6px;
        overflow: hidden;
    }
}


//Modal header

.logo {
    img {
        width: 100%;
    }
}

.modalHeader {
    background-color: #fff;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
}

//Modal body
.modalBody {
    width: 100%;
}

//Modal footer
.modalFooter {
    text-align: center;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
}

.footerText {
    color: #999999;

    img {
        margin-right: 1rem;
    }
}

//Label

.headerLabel {
    color: #999999;
    font-size: 14px;
    line-height: 30px;
    padding-right: 15px;
}

.headerValue {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
}

.headerValuePrice {
    font-size: 26px;
    line-height: 36px;
    color: #4da552;
}
