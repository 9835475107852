.error-wrapper {
    max-width: 370px;
    margin: auto;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        margin-bottom: 20px;
    }

    .error-message {
        color: #ED1D1D;
        font-size: 18px;
        text-align: center;
    }

    button {
        margin-top: 30px;
        height: 40px;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
        padding: 6px 12px;
        font-size: 14px;
        text-transform: uppercase;

        &:hover {
            background-color: #286090;
            border-color: #204d74;
        }
    }
}