.processing {
    padding: 20px 30px;
    min-height: 250px;
    color: #333;
    display: flex;
    position: relative;
}

.payment-processing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__text {
        text-align: center;
        width: 100%;

        p {
            padding: 0;
            margin: 0 0 15px 0;
            font-size: 16px;
        }
    }

    &__timer {
        font-size: 50px;
        color: #0892cd;
        margin: 10px 0 0 0;
    }
}

.recreate-text {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
}
.recreate-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #0892cd;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0;
    font-size: 14px;

    &:hover {
        background-color: darken(#0892cd, 10%);
    }
}

.payment-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;

    &__title {
        font-size: 18px;
        margin: 0;
        max-width: 350px;
        color: #333;
    }
}

//Icon
.payment-processing-icon {
    margin-bottom: 20px;
    width: 40px;

    svg {
        width: 100%;
    }

    &--info {
        svg {
            fill: #0892cd;
        }
    }

    &--error {
        svg {
            fill: red;
        }
    }
}

//Loader
.loader-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.8);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #0892cd;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
