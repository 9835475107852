.informationBlock {
    padding: 1.5rem 15px 1.5rem 0;
    line-height: 34px;
}

.missingBlock {
    padding: 1.5rem 15px 1.5rem;
    line-height: 34px;

    input, select {
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        outline: none;

        &:focus {
            border-color: #66afe9;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
        }

    }
}

.inlinePrice {
    word-wrap: break-word;
    color: #0892cd;
    font-size: 3rem;
    font-weight: bold;
}



//Sellar block

.sellerBlock {
    position: relative;
    padding-top: 1.5rem;
}
