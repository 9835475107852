.blockHeaderWrapper {
    position: relative;
    padding: 1px 0;
    border-bottom: 1px solid #DDDDDD;
    border-top: 1px solid #DDDDDD;

    .blockCardIcon {
        background: #eee left center no-repeat;
        img {
            margin-right: 1.1rem;
        }
    }
}