.container {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 1px 0;
    margin: -1px 0 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    .logoWrapper {
        padding: 0 5px;
        line-height: 68px;
        min-width: 110px;

        img {
            width: 100px;
            height: auto;
        }
    }

    .textWrapper {
        width: 100%;
        min-height: 68px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        overflow: hidden;
    }

    .description {
        font-size: 12px;
    }

    .arrowWrapper {
        padding: 0 10px;
    }

    &:hover {
        background: #f2f2f2;
        .arrowWrapper {
            filter: brightness(0.5);
        }
    }
}

@media (min-width: 480px) {
    .container {
        .logoWrapper {
            padding: 0 0 0 25px;
            min-width: 225px;

            img {
                width: 150px;
                max-width: none;
            }
        }
    }
}
