@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/spacing";


@import '../../node_modules/paysera-checkout-modal/dist/app.css';
@import '../../node_modules/paysera-loading-spinner/dist/app.css';

html {
    font-size: 10px;
}

html, body, #root, .loadedPageWrap {
    height: 100%;
}

body {
    background-color: transparent;
    font-size: 14px;
}

#root {
    .Loader, .Loader__content {
        height: inherit;
    }
}

section {
    position: relative;
    overflow: hidden;
    width: 100%;
}


img {
    display: inline-flex;
}

label,
button,
input,
textarea {
    transition: all 0.5s ease;
    outline: none;
    &:focus {
        outline: none;
    }
}


button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}


.Select-menu-outer {
    @media (max-width: 768px) {
        position: relative;
    }
}

.modal-dialog .card-logos-row {
    display: none;
}

.text-right {
    text-align: right;
}

.full-page-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
}


//Modal styles
.checkout-modal {
    .card-logos-row {
        display: none;
    }
}

//Fields
.text-danger {
    color: #a94442;
}

.form-group-submit {
    &.text-right {
        text-align: right;
    }

    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        cursor: pointer;
        border: 1px solid transparent;
        padding: 6px 12px;
        font-size: 14px;
        border-radius: 4px;
        user-select: none;
        line-height: 1.3;

        &.btn-primary {
            color: #fff;
            background-color: #337ab7;
            border-color: #2e6da4;
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.65;
            box-shadow: none;
        }
    }
}

