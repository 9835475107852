@import '../../styles/variables';

.message {
    text-align: center;
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    &.failed {
        color: $error-color;
    }

    &.success {
        color: $success-color;
    }
}
