@import '../../../../styles/variables';

.successWrap {
    height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .successHeader {
        color: $success-color;

        .successIcon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 50px;
                fill: #5cb85c;
            }
        }

        .successMessage {
            color: #5cb85c;
            margin: 0;
        }
    }
}

.timerWrap {
    margin: 1rem 0;
}

.closeButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #5cb85c;
    padding: 6px 12px;
    margin: 0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;

    .closeIcon {
        display: inline-flex;
        margin: 0 0 0 5px;

        svg {
            width: 20px;
            fill: #fff;
        }
    }

    &:hover {
        color: #fff;
        background-color: #449d44;
    }
}
