@import '../../../styles/variables';

.footer {
    background: #ffffff;
    box-shadow: 0 -1px 4px rgba(50, 50, 50, 0.15);
    width: 100%;
}

.rightBlock {
    text-align: right;
    padding: 20px 0;
}

.leftBlock, .rightBlock {
    @media (max-width: 768px) {
        text-align: center;
    }
}

.leftBlock {
    span {
        margin-right: 10px;
    }
    span:last-of-type {
        margin-right: 0;
    }

    padding: 20px 0;
}
