.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;

    .header {
        color: #8C8C8C;
        text-align: center;
    }

    .weAreSorryText, .solutionText {
        text-transform: uppercase;
        text-align: center;
    }

    .header, .weAreSorryText, .solutionText {
        font-weight: bold;
    }
}
