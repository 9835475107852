.container {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    .titleWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-right: 1px solid #e8e8e8;
    }

    .selectWrapper {
        width: 100%;
        padding: 10px;
    }
}
