@import './FullPageWrap.module';

$cancel-url-block-height: 40px;

section.payment-section {
    padding: 20px 0;
    flex-grow: 1;
}

.columnContainer {
    width: 1000px;
    min-width: 300px;
    margin: 0 auto;
}

.singleColumn {
    width: 100%;
    max-width: 550px;
}

.leftPanel, .rightPanel {
    margin-bottom: 2rem;
}

.innerLeftPanel, .innerRightPanel, .singleColumn {
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(50, 50, 50, 0.25);
}

.fullPage {
    margin: 0 auto calc(#{-$full-page-footer-height} - #{$cancel-url-block-height});
}

.footerPush {
    height: calc(#{$full-page-footer-height} + #{$cancel-url-block-height});
}

.cancelUrlText {
    text-decoration: underline;
    padding-left: 1rem;
}

@media (max-width: 1000px) {
    .columnContainer {
        width: 100%;
    }
}

@media (max-width: 767px) and (min-width: 480px) {
    .columnContainer {
        width: 470px;
    }
}

@media (max-width: 767px) {
    .singleColumn {
        margin: 0 auto;
        width: auto;
        min-width: 300px;
        max-width: 470px;
    }
}
