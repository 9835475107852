@import '../../../styles/variables';

.fullPageWrap {
    height: 100%;
}

.fullPage {
    min-height: 100%;
    background-color: $background-color;
    margin: 0 auto #{-$full-page-footer-height};
    display: flex;
    flex-direction: column;
}

.footerPush {
    height: $full-page-footer-height;
}
