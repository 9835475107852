.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f2f2;
    color: #777;
    font-size: 1.6rem;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1px 0;
    margin: 0;
    min-height: 35px;
}

.toggle {
    display: inline-flex;
    justify-content: center;
}

.label.noIcon {
    text-indent: 25px;
}

.toggle.toggled img{
    transform: rotate(90deg)
}

.image {
    padding: 0 !important;
}

.toggleable {
    cursor: pointer;
}
