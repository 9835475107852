.childrenContainer {
    position: absolute;
    color: rgba(33, 37, 41, 0.7);
    width: 100%;
}

.spinnerContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.55);
}
